@use 'assets/scss/colors';

.user-profile-menu {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 80px;
	right: 30px;
	border: 1px solid colors.$line-line;
	width: 140px;
	background-color: colors.$bg-block;
	border-radius: 10px;
	color: colors.$text-primary;

	&__avatar {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		overflow: hidden;
	}
	&__text-wrapper {
		padding: 5px 20px;

		&:nth-last-child(2n + 2) {
			border-bottom: 1px solid colors.$line-line;
			padding-bottom: 10px;
		}

		&:nth-last-child(3) {
			padding-top: 10px;
		}

		&:first-child {
			padding-top: 10px;
		}

		&:last-child {
			padding: 10px 20px;
		}
	}
	&__text {
		display: flex;
		align-items: center;

		width: 100%;
		height: 30px;
		color: colors.$text-primary;
		&:hover {
			cursor: pointer;
			color: colors.$text-link-active;
		}
	}
}

@media (max-width: 660px) {
	.user-profile-menu {
		top: 85px;
		right: 10px;

		z-index: 1;
	}
}
