@use 'assets/scss/colors';

.tag-header {
	position: relative;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 16px;
	color: colors.$text-primary;

	&__logo-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		min-width: 80px;
		margin: 0 16px 0 0;
		border-radius: 50%;
		overflow: hidden;
		padding: 8px;
		border: 1px solid colors.$line-line;
	}

	&__logo {
		position: absolute;
		border-radius: 50%;
	}

	&__text-block {
		justify-content: center;
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	&__subtitle {
		color: colors.$text-secondary;
		font-style: italic;
	}

	&__name {
		margin-bottom: 8px;
	}

	&__number,
	&__position {
		color: colors.$text-secondary;
	}

	&__last-five {
		display: flex;
		gap: 4px;
	}

	&__last-five-item {
		width: 8px;
		height: 8px;
		border-radius: 50%;

		&--win {
			background-color: colors.$icons-green;
		}

		&--lose {
			background-color: colors.$icons-red;
		}

		&--draw {
			background-color: colors.$icons-gray;
		}
	}

	span::first-letter {
		text-transform: uppercase;
	}

	&__go-to-admin {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 100px;
	}
}

@media (max-width: 660px) {
	.tag-header {
		&__go-to-admin {
			position: static;
			margin-left: auto;
		}
	}
}
